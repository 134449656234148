<template>
  <div style="width: 100%;" id="tishikuang">
    <div :id="id" :style="{width: width, height: height}"></div>
<!--    <div style="display: flex;align-items: center;justify-content: space-between;padding: 15px 15px 0 45px;">-->
<!--      <div style="display: flex;flex-direction: column;align-items: center;">-->
<!--        <a-icon type="plus-square" @click.stop="changeTongjiTu(0,1)" />-->
<!--        <a-icon type="minus-square" @click.stop="changeTongjiTu(0,0)" style="margin-top: 10px" />-->
<!--      </div>-->
<!--      <div style="display: flex;flex-direction: column;align-items: center">-->
<!--        <a-icon type="plus-square" @click.stop="changeTongjiTu(1,1)" />-->
<!--        <a-icon type="minus-square" @click.stop="changeTongjiTu(1,0)"  style="margin-top: 10px" />-->
<!--      </div>-->
<!--      <div style="display: flex;flex-direction: column;align-items: center">-->
<!--        <a-icon type="plus-square" @click.stop="changeTongjiTu(2,1)" />-->
<!--        <a-icon type="minus-square" @click.stop="changeTongjiTu(2,0)" style="margin-top: 10px" />-->
<!--      </div>-->
<!--      <div style="display: flex;flex-direction: column;align-items: center;">-->
<!--        <a-icon type="plus-square" @click.stop="changeTongjiTu(3,1)" />-->
<!--        <a-icon type="minus-square" @click.stop="changeTongjiTu(3,0)" style="margin-top: 10px" />-->
<!--      </div>-->
<!--      <div style="display: flex;flex-direction: column;align-items: center;">-->
<!--        <a-icon type="plus-square" @click.stop="changeTongjiTu(4,1)" />-->
<!--        <a-icon type="minus-square" @click.stop="changeTongjiTu(4,0)" style="margin-top: 10px" />-->
<!--      </div>-->

<!--      <div style="display: flex;flex-direction: column;align-items: center;">-->
<!--        <a-icon type="plus-square" @click.stop="changeTongjiTu(5,1)" />-->
<!--        <a-icon type="minus-square" @click.stop="changeTongjiTu(5,0)"  style="margin-top: 10px" />-->
<!--      </div>-->

<!--      <div style="display: flex;flex-direction: column;align-items: center;">-->
<!--        <a-icon type="plus-square" @click.stop="changeTongjiTu(6,1)"  />-->
<!--        <a-icon type="minus-square" @click.stop="changeTongjiTu(6,0)"  style="margin-top: 10px" />-->
<!--      </div>-->

<!--      <div style="display: flex;flex-direction: column;align-items: center;">-->
<!--        <a-icon type="plus-square" @click.stop="changeTongjiTu(7,1)"  />-->
<!--        <a-icon type="minus-square" @click.stop="changeTongjiTu(7,0)" style="margin-top: 10px" />-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "myCustomEcharts",
  props: {
    id: {
      type: String,
      required: true
    },
    width: {
      type: String
    },
    height: {
      type: String
    },
    option: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      chartInstance: null
    };
  },
  mounted() {
    // if (this.width) {
    //   (document.querySelector('.chart')).style.width = this.width + 'px';
    // }
    this.chartInstance = echarts.init(document.getElementById(this.id));
    this.chartInstance.setOption(this.option);
  },
  beforeDestroy() {
    if (this.chartInstance) {
      this.chartInstance.dispose();
    }
  },
  methods: {
    changeTongjiTu(index,option) {
      if(this.chartInstance.getOption()) {
        let chartData = this.chartInstance.getOption().series[0].data;
        if(option == 1) {
          //+
          chartData[index] = chartData[index]+1;
          console.log(chartData)
          this.chartInstance.setOption({
            series:[{
              data: chartData,
            }]
          })
        } else {
          //-
          if(chartData[index] === 0) {
            return;
          }
          chartData[index] = chartData[index]-1;
          this.chartInstance.setOption({
            series:[{
              data: chartData,
            }]
          })
        }
        this.$emit('changeCharts',chartData)
      }

    }
  }
}
</script>

<style scoped>

</style>